






import Vue from 'vue'
import SearchInstanceStore from '@/store/search/searchInstance'
import searchTryModule from '@/store/searchTry/searchTryModule'

export default Vue.extend({
  components: {},
  computed: {
    searchId: {
      get(): string {
        return SearchInstanceStore.state.searchInstanceId
      },
    },
    frameTesterUrl: {
      get(): string {
        return searchTryModule.getters.getFrameTesterUrl
      },
    },
  },
  watch: {
    searchId() {
      searchTryModule.dispatch('changeSearchIdFrameTester', { searchId: this.searchId, frame: this.$refs.frameTester })
    },
  },
  methods: {
    setFrameLoaded() {
      searchTryModule.dispatch('loadedFrame', { searchId: this.searchId, frame: this.$refs.frameTester })
    },
  },
})

