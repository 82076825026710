import Vue from 'vue'
import Vuex from 'vuex'
import store from '@/store'
import CustomerStore from '@/store/user/customer'

const SEARCH_TESTER_PUBLIC_URL = 'https://cdn.quartic.com.pl/search/v3_app/index.html'

Vue.use(Vuex)

interface SearchTryState {
  customerSymbol: string | null
  searchId: string | null
  authKey: string | null
  isAuthDataReady: boolean
  isFrameLoaded: boolean
  searchTesterUrl: string | null
}

export default new Vuex.Store({
  state: (): SearchTryState => ({
    authKey: null,
    searchId: null,
    customerSymbol: null,
    isAuthDataReady: false,
    isFrameLoaded: false,
    searchTesterUrl: null,
  }),
  mutations: {
    setCustomerSymbol(state: SearchTryState, customerSymbol: string) {
      state.customerSymbol = customerSymbol
    },
    setSearchId(state: SearchTryState, searchId: string) {
      state.searchId = searchId
    },
    setAuthReady(state: SearchTryState, isAuthDataReady: boolean) {
      state.isAuthDataReady = isAuthDataReady
    },
    setFrameLoaded(state: SearchTryState, isFrameLoaded: boolean) {
      state.isFrameLoaded = isFrameLoaded
    },
  },
  actions: {
    async getApiAuthorizationKey() {
      await store.dispatch('authorizationKey/getData')
        .then((response: { key: string }) => {
          this.state.authKey = response.key
        })
    },

    loadedFrame(context: any, { searchId, frame }) {
      if (!context.state.isFrameLoaded) {
        context.commit('setFrameLoaded', true)
        context.dispatch('changeSearchIdFrameTester', { searchId, frame })
      }
    },

    async changeSearchIdFrameTester(context: any, { searchId, frame }) {
      if (!context.state.isFrameLoaded) {
        return
      }
      if (context.state.authKey === null) {
        await context.dispatch('getApiAuthorizationKey')
      }
      if (context.state.customerSymbol === null) {
        context.commit('setCustomerSymbol', CustomerStore.state.customerSymbol)
      }
      context.commit('setSearchId', searchId)
      context.commit('setAuthReady', true)
      await context.dispatch('sendSearchInformationToFrame', frame)
    },

    sendSearchInformationToFrame(context: any, frame: HTMLIFrameElement) {
      if (!frame.contentWindow) {
        return
      }
      frame.contentWindow.postMessage({
        customerSymbol: context.state.customerSymbol,
        searchId: context.state.searchId,
        apiKey: context.state.authKey,
      }, '*')
    },
  },
  getters: {
    getFrameTesterUrl() {
      return SEARCH_TESTER_PUBLIC_URL
    },
  },
})
